<template>
    <div class="shop_page_header">
        <div class="shop_page_content">
            <div class="info">
                <a href="javascript:void(0)" class="backBtn" v-if="defaultActive !== '/index'" @click="$router.replace('/index')">
                    <img src="../../../assets/img/home_back.png" />
                    <span>返回商城首页</span>
                </a>

                <span class="text" v-else>HI~欢迎来到易诚药材通</span>

                <template v-if="isLogin">
                    <h6 class="userName">{{nickname}}</h6>
                    <a href="javascript:void(0)" class="register" @click="toExit">【退出】</a>
                </template>

                <template v-else>
                    <a href="javascript:void(0)" @click="$router.push('/login')" class="login">【请登录】</a>
                    <a href="javascript:void(0)" @click="$router.push('/register')" class="register">【免费注册】</a>
                </template>


                <div class="notice" v-if="noticeData.length > 0">
                    <img src="../../../assets/img/notice.png" class="icon" />
                    <el-carousel height="39px" direction="vertical" :autoplay="true" :interval="5000">
                        <el-carousel-item v-for="(item, index) in noticeData" :key="index">
                            <span class="text" @click="$router.push({path: '/shop/notice/detail', query: {id: item.id}})">{{item.title}}</span>
                        </el-carousel-item>
                    </el-carousel>
                    <span class="more" @click="$router.push('/shop/notice')">更多<img src="../../../assets/img/more.png" /></span>
                </div>
            </div>


            <div class="content">
                <div class="order">
                    <div class="label">个人中心<img src="../../../assets/img/downBtn.png" /></div>
                    <div class="list">
                        <a href="javascript:void(0)" @click="goShopLogin(isLogin, '/shop/order')">订单管理</a>
                        <a href="javascript:void(0)" @click="goShopLogin(isLogin, '/shop/keep')">我的收藏</a>
                        <a href="javascript:void(0)" @click="goShopLogin(isLogin, '/shop/address')">地址管理</a>
                        <a href="javascript:void(0)" @click="goShopLogin(isLogin, '/shop/account')">个人资料</a>
                        <a href="javascript:void(0)" @click="goShopLogin(isLogin, '/shop/supplyBuying/quotation')">供求信息</a>
                        <a href="javascript:void(0)" @click="goShopLogin(isLogin, '/shop/contract')">合同管理</a>
                    </div>
                </div>

                <div class="order">
                    <div class="label">商家中心<img src="../../../assets/img/downBtn.png" /></div>
                    <div class="list">
                        <a href="javascript:void(0)" @click="goAdminMerchant">商家认证</a>
                        <a href="javascript:void(0)" @click="goAdminLogin(isLogin, status, '/admin/order')">订单管理</a>
                        <a href="javascript:void(0)" @click="goAdminLogin(isLogin, status, '/admin/goods/sell')">商品管理</a>
                        <a href="javascript:void(0)" @click="goAdminLogin(isLogin, status, '/supplyBuying/buying')">供求管理</a>
                        <a href="javascript:void(0)" @click="goAdminLogin(isLogin, status, '/admin/store/backend')">店铺管理</a>
                    </div>
                </div>

                <img src="../../../assets/img/phone.png" class="icon" />
                <span class="phone">{{consumerPhone}}</span>
            </div>
        </div>
    </div>
</template>

<script>
  import {goShopLogin, goAdminLogin} from '../../../utils/method'
  import {consumerPhone} from '../../../utils/config'
  import {mapState} from "vuex";
  export default {
    name: "LayoutHeader",
    props: {
      nickname: {
        type: String,
        default: ''
      },
      noticeData: {
        type: Array,
        default: () => {
          return []
        }
      }
    },
    data () {
      return {
        goAdminLogin,
        goShopLogin,
        consumerPhone
      }
    },
    computed: {
      ...mapState({
        status: state => state.user.userStatus,
        isLogin: state => state.user.isLogin,
        defaultActive: state => state.user.defaultActive,
      })
    },
    methods: {
      toExit () {
        this.$confirm('是否确认退出登录?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          closeOnClickModal: false,
          type: 'warning'
        }).then(() => {
          localStorage.removeItem('access_token_shop')
          this.$store.commit('changeLoginType', false)
        }).catch(() => {});
      },
      goAdminMerchant () {
        if (this.isLogin) {
          if (Number(this.status) === 0) {
            this.$confirm('您还未提交实名认证，是否提交?', '提示', {
              confirmButtonText: '提交',
              cancelButtonText: '取消',
              closeOnClickModal: false,
              type: 'warning'
            }).then(() => {
              this.$router.push('/shop/realName')
            }).catch(() => {});
          } else if (Number(this.status) === 6) {
            this.$router.push('/admin/merchant/detail')
          } else {
            this.$router.push('/admin/merchant/edit')
          }
        } else {
          this.$router.push('/login')
        }
      },
    }
  }
</script>
